.edit__header{
  margin-bottom: $spacing-xs;
}
.edit__title{
  display:block;
  @extend .ts-title;
}

.panel {
  &.panel-edit {
    @include media(">=sm") {
      width: 66%;
      display: inline-block;
    }
    &.panel-edit--single{
      display:block;
      margin: 0 auto;
      .panel-heading{
        padding-bottom: $spacing-xs;
      }
    }
    .panel-heading {
      padding-top: $spacing-xs;
      padding-bottom: $spacing-xs;
      display:flex;
      align-items: center;
      justify-content: space-between;
      .tab-select {
        flex-shrink: 0;
      }
      .edit-details {
        text-align: right;
        > * {
          display: inline-block;
          &:not(:first-child) {
            margin-left:$spacing-xs;
          }
        }
        .created_at{
        }
        input {
          line-height:1;
        }
      }
    }
  }
  .panel-body + .panel-body {
    margin-top: $spacing-sm;
  }
}

.created_at input {
  border: none;
  padding: 0;
  text-align: right;
  background-color:transparent;
  color:$gray;
  &:focus{
    color:$black;
  }
}

.edit-details .is-published {
  label {
    margin-left: $spacing-xs;
    cursor:pointer;
  }
  input[type="checkbox"] {
    display:none;
  }
  span::before{
    @extend .icon-published;
    content:"\f10c";
    font-family: 'FontAwesome';
  }
  :checked + span::before{
    content:"\f111";
  }
}

.form-group {
  .slug {
    @extend .ts-small;
    display:inline-block;
    color: $gray;
    margin-top: $spacing-xxs;
  }
}
