
.table-responsive {
  .btn{
    position:absolute;
    right:0;
    top:0.8rem;
  }
  .panel-body:not(.panel-body--medias) {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .panel-heading {
    padding-top:0;
    margin-bottom: $spacing-xs;
    .dataTables_filter {
      label{
        width: 80%;
        @include media("<sm") {
          width: calc(100% - 100px);
        }
      }
      input {
        border: none;
        background-color:transparent;
        padding:0;
        width: 100%;
        @extend .ts-title;
        cursor:pointer;
        &::placeholder { /* Chrome/Opera/Safari */
          color: $base-color !important;
          opacity: 1 !important;
        }
        &:hover::placeholder{
          opacity:0.5 !important;
        }
        &:focus{
          cursor:text;
          outline:none;
        }
      }
    }
  }

}


.dataTables_wrapper{
  .table {
    border: none;
    thead{
      th {
        @extend .ts-label;
        padding-bottom:$spacing-xxs !important;
      }
    }
    tr {
      &:not(:last-of-type) {
        border-bottom: $border-width-sm solid $lightgray;
      }
      position:relative;
      th,
      td {
        padding: 0 5px 0 calc(#{$grid-gutter-width} / 2);
        max-width: 20vw;
        &.main-column{
          width:60%;
          max-width: 50vw;
          padding-top: $spacing-xs * 1.5;
          padding-bottom: $spacing-xs * 1.5;
          a {
            @extend .link;
            border-bottom: none;
            color: $base-color;
          }
        }
        &:not(th):not(.main-column):not(.sorting_1){
          @extend .ts-small;
          color:$gray;
        }
        .text-content{
          display: block;
          width:100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        &.is-published {
          width:0;
          text-align: center;
          & + td,
          & + th {
            padding-left: 0;
          }
        }
        &:first-child {
          padding-left :calc(#{$grid-gutter-width} / 2);
          i{
            margin-bottom: 7px;
          }
        }
        &:last-child {
          text-align:right;
          padding-right :calc(#{$grid-gutter-width} / 2);
        }
        &.blue{
          color: $blue !important;
        }
      }
    }
  }

  .panel-body .highlight {
    background-color: $lightyellow;
  }

  .panel-footer {
    list-style: none;
    display:flex;
    justify-content: space-between;
    .dataTables_paginate {
      a {
        margin-right:$spacing-xxs;
        @extend .link;
        border: none;
        // &.current {
        //   border-bottom: $border-width-sm solid;
        // }
      }
    }
    @include media("<sm") {
      flex-direction: column-reverse;
      .dataTables_paginate {
        font-size: $font-size-lg;
        text-align: center;
        margin-bottom: $spacing-sm;
      }
    }
  }
  .dataTables_info{
    @extend %font-size-sm;
  }
  .dataTables_length,
  .dataTables_info {
    display: inline-block;
  }
  .dataTables_length {
    margin-right: $spacing-xs;
    &:after {
      content: "/";
      margin-left: $spacing-xxs;
    }
  }
}
